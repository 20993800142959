import { App } from 'views/App'
import { getSubDomain } from 'shared/getSubdomain'
import { PageWrapper } from 'components/PageWrapper'

export const Page = ({ subdomain }) => (
  <PageWrapper subdomain={subdomain}>
    <App pageId="homepage" />
  </PageWrapper>
)

Page.getInitialProps = async ({ req, query }) => {
  let subdomain = null

  if (req) {
    subdomain = getSubDomain(req.headers.host)
  }

  if (typeof window !== 'undefined') {
    subdomain = getSubDomain(window.location.host)
  }

  return { subdomain }
}

export default Page
